import './bootstrap';
import Vue from 'vue';
import Vuetify from 'vuetify';
// import store from './stores';
// import Routes from '@/js/routes.js';
import Login from '@/js/pages/Login';


Vue.use(Vuetify, {
    theme: {
        primary: '#002ea7',
        secondary: '#002ea7',
        accent: '#8c9eff',
        error: '#da291c',
        warning: '#ff8200',
        info: '#00a3ad',
        success: '#97d700'
    },
    iconfont: 'md'
});

const app = new Vue({
    el: '#app',
    components: { Login }
});






