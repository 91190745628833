<template>
    <v-app id="marketing-plattform">
        <v-content>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-form v-model="formIsValid" v-on:submit="submit" @keydown.enter.prevent lazy-validation
                                action="/signin" method="post" ref="loginForm" id="loginForm">
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>Anmeldung</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="text-xs-right mb-0 pb-0">
                                    <v-icon class="" v-show="showInfo == false" color="secondary" @click="showInfo = true">
                                        help_outline
                                    </v-icon>
                                    <v-alert v-model="showInfo" class="login-info text-xs-left mb-3 text--primary"
                                        color="white" dismissible>
                                        Sie benötigen kein Passwort für Ihre Anmeldung!<br>Geben Sie einfach Ihre
                                        Mailadresse ein. Sie erhalten dann eine Mail mit einem Zugangslink für die
                                        Marketing-Plattform.
                                    </v-alert>
                                </v-card-text>
                                <v-card-text class="pt-0 mt-0">
                                    <v-text-field value="" ref="email" id="email" :rules="emailRules"
                                        prepend-icon="person" name="email" label="Mail" type="email"
                                        placeholder="...@oeffentliche.de" required></v-text-field>
                                    <input type="hidden" name="_token" id="_token" value="">
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="!formIsValid" @click.stop="submit"
                                        color="primary">Absenden</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
        <v-footer class="px-4">
            <span>Marketing-Plattform der Öffentlichen Versicherung Braunschweig</span>
            <v-spacer></v-spacer>
            <span>&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
    </v-app>
</template>

<script>
import UserService from "../services/UserService";

export default {
    name: 'Login',
    props: {
        source: String
    },
    data() {
        return {
            formIsValid: false,
            showInfo: false,
            emailRules: [
                v => !!v || "Die Mailadresse wird benötigt.",
                v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Ungültige Mailadresse!",
                /* v => /(@oeffentliche\.de)|(@sicht-kontakt\.de)|(@fp-grafikdesign.com)$/.test(v) || "Nicht zulässige Mailadresse!" */
            ]
        }

    },
    methods: {
        submit: function (event) {
            if (this.$refs.loginForm.validate()) {
                loginForm.submit();
                return true;
            }
        }
    },
    mounted: function () {
        //document.forms[0].focus();
        // document.getElementById("app").click();
        document.getElementById('_token').value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        console.dir(this.$refs.email.$el);
        this.$refs.email.$el.querySelector('input').focus();
    },
    created: function () {
        // document.getElementById('_token').value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
}
</script>

<style>
.login-info {
    border-width: 1px 0 1px 0;
}

.login-info .v-alert__dismissible .theme--light.v-icon {
    transform: translate(16px, 32px);
    color: #002ea7;
}
</style>
