import axios from 'axios'

const apiClient = axios.create();

export default {
    getUser() {
        return apiClient.post('auth/getUser');
    },
    getUserRole() {
        return apiClient.post('auth/getUserRole');
    },
    getMkUsers(category) {
        return apiClient.post('/admin/users/index',
            {
                'category': category
            });
    },
    isKnownMailaddress(email){
        return apiClient.post('/admin/users/mailcheck',
            {
                'email': email
            });
    },
    storeMkUser(mkUser) {
        return apiClient.post('/admin/users/updateOrCreate',
            {
                'mkUser': mkUser
            });
    },
    deleteMkUser(mkUserId) {
        return apiClient.post('/admin/users/delete',
            {
                'id': mkUserId
            });
    }
}
